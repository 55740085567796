import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Avatar from 'avataaars'
import Tilt from "react-parallax-tilt";
import { GoMarkGithub } from "react-icons/go";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I am a passionate Software Engineering Student, fluent in programming languages like
              <i>
                <b className="purple"> Kotlin, Javascript and Python.</b>
              </i>
              <br />
              <br />
              I enjoy building&nbsp;
              <i>
                <b className="purple">Android Apps </b>
              </i>
              that utilise&nbsp;
              <i>
                <b className="purple">
                  Modern Android Frameworks,&nbsp;
                </b>
              </i>
              like&nbsp;
              <i>
                <b className="purple">
                  Jetpack Compose.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js.</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <Avatar
                avatarStyle='Circle'
                topType='ShortHairShortCurly'
                accessoriesType='Prescription02'
                hairColor='Red'
                facialHairType='Blank'
                clotheType='CollarSweater'
                clotheColor='Black'
                eyeType='Happy'
                eyebrowType='DefaultNatural'
                mouthType='Smile'
                skinColor='Light'
              />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/russellbanks"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <GoMarkGithub />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
