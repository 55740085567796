import React from "react";
import Card from "react-bootstrap/Card";
import { ImArrowRight2 } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello, I am <span className="purple">Russell Banks </span>
            from <span className="purple"> the United Kingdom.</span>
            <br />I am a Computer Science student following my strong passion for technological creativity, fusing my knowledge of programming and my desire to work towards a world of libre software.
            <br />
            <br />
            Aside from programming, here are some of my other interests!
          </p>
          <ul>
            <li className="about-activity">
              <ImArrowRight2 /> Games
            </li>
            <li className="about-activity">
              <ImArrowRight2 /> Music
            </li>
            <li className="about-activity">
              <ImArrowRight2 /> Cyber Security
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
